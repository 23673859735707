import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Switch,
  Typography,
  alpha,
  styled,
} from '@mui/material';

const ConfigContainer = styled(Box)(({ theme }) => ({
  margin: '1.25rem 1.25rem 0rem 1.25rem',
  border: `0.313em solid ${theme.palette.background.layoutBackgroundColor}`,
  borderRadius: '4px 4px 0px 0px',
  height: '100%',
}));

const ConfigTitle = styled(Typography)({
  paddingTop: '0.813rem',
  paddingLeft: '1.313rem',
  fontWeight: 400,
  fontSize: '1.5rem',
  lineHeight: '2.043rem',
});

const SubConfigTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: '0.875rem',
  lineHeight: '1.192rem',
});

const SubConfigContentTypography = styled(Typography)({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
});

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  placeSelf: 'center',
  boxShadow: 'none',
  margin: '0 0 0.313 0',
  background: theme.palette.background.blackGrey,
  border: `1px solid ${theme.customColors.wrapperGrey}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.MuiAccordion-root.Mui-expanded': {
    margin: '0 0 0.313 0',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'isAlert',
})(({ theme, isAlert }) => ({
  minHeight: '3.5rem !important',
  backgroundColor: theme.palette.background.darkishBlackBlue,
  borderBottom: `1px solid ${alpha(theme.customColors.black, 0.125)}`,
  paddingLeft: '1rem',
  paddingRight: '1.5rem',
  borderRadius: '4px',
  height: isAlert ? '' : '3.5rem',
  '&$expanded': {
    minHeight: '3.5rem',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    marginRight: '1rem',
    marginTop: '1.156rem !important',
    marginBottom: '1.156rem !important',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '1.156rem 1rem 1.156rem 0px !important',
  },
  '&.Mui-disabled': {
    color: theme.palette.text.darkGrey,
    opacity: 1,
    '& .MuiAccordionSummary-expandIcon': {
      filter:
        'brightness(0) saturate(100%) invert(35%) sepia(3%) saturate(630%) hue-rotate(175deg) brightness(96%) contrast(98%)',
    },
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.background.darkishBlue,
  padding: '0',
  overflowX: 'hidden',
  overflowY: 'auto',
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '&.MuiSwitch-root': {
    width: '2.26rem',
    height: '1.125rem',
    padding: 0,
    display: 'flex',
  },
  '& .MuiSwitch-switchBase': {
    padding: '0.06rem 0.15rem 0.1rem 0.1rem',
    '&.Mui-checked': {
      transform: 'translateX(1.125rem)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.background.saveGreen,
      },
    },
    '&.Mui-disabled ': {
      '& + .MuiSwitch-track': {
        opacity: 1,
        background: theme.palette.background.shuttleGray,
      },
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: '1rem',
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: '7.5rem',
  height: '2.25rem',
  borderRadius: '1.25rem',
  margin: 'auto 1rem auto auto',
  backgroundColor: theme.palette.background.saveGreen,
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  lineHeight: '1rem',
  fontWeight: 600,
  '&:hover': {
    color: theme.palette.text.primary,
    background: 'inherit',
    border: `0.0625rem solid ${theme.palette.border.saveGreen}`,
  },
  '&:disabled': {
    color: theme.palette.background.twentySevenJungleGrey,
    background: theme.palette.background.darkGrey,
    border: `0.0625rem solid ${theme.palette.border.wrapperGrey}`,
  },
}));

const ButtonTypography = styled(Typography)({
  color: 'inherit',
  fontSize: '0.875rem',
  lineHeight: '1rem',
  fontWeight: 600,
  textTransform: 'none',
});

const SubConfigFooter = styled(Box)(({ theme }) => ({
  height: '3.5rem',
  backgroundColor: theme.palette.background.blackGrey,
  display: 'flex',
}));

const SubConfigContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '3rem',
  alignItems: 'center',
  backgroundColor: theme.palette.background.blackGrey,
  borderTop: `1px solid ${theme.customColors.wrapperGrey}`,
  borderBottom: `1px solid ${theme.customColors.wrapperGrey}`,
}));

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const BackdropContainer = styled(Box)(() => ({
  display: 'flex',
  maxHeight: '100%',
  flex: 1,
  flexDirection: 'column',
}));

const DangerText = styled(Box)(({ theme }) => ({
  color: theme.palette.background.default,
  textAlign: 'center',
}));

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
}));

const StyledToastTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '1.192rem',
  color: theme.customColors.white,
}));

export {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  ConfigContainer,
  StyledSwitch,
  ConfigTitle,
  SubConfigTitle,
  SubConfigContentTypography,
  StyledButton,
  SubConfigFooter,
  ButtonTypography,
  SubConfigContentWrapper,
  CustomBackdrop,
  BackdropContainer,
  DangerText,
  StyledBackdrop,
  StyledToastTypography,
};

import PropTypes from 'prop-types';
import CheckIcon from '../../assets/switchChecked.svg';
import UncheckIcon from '../../assets/switchUnchecked.svg';
import { StyledSwitch } from '../../utils/styles';

function Switch({ checked, onChange, disabled, ...props }) {
  return (
    <StyledSwitch
      data-testid="switch"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      disableRipple
      icon={<UncheckIcon />}
      checkedIcon={<CheckIcon />}
      {...props}
    />
  );
}

Switch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
export default Switch;

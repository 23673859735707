import React from 'react';
import PropTypes from 'prop-types';
import AccordionDownArrow from '../../assets/accordionDownArrow.svg';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from '../../utils/styles';

function AccordionSummaryFun({ expandIcon = null, isAlert = false, ...props }) {
  return (
    <StyledAccordionSummary
      expandIcon={
        expandIcon || <AccordionDownArrow className="fhc-validation-icon" />
      }
      isAlert={isAlert}
      {...props}
    />
  );
}

AccordionSummaryFun.propTypes = {
  expandIcon: PropTypes.node,
  isAlert: PropTypes.bool,
};
function AccordionDetailsFun({ children, ...other }) {
  return <StyledAccordionDetails {...other}>{children}</StyledAccordionDetails>;
}
AccordionDetailsFun.propTypes = {
  children: PropTypes.node,
};
function CustomizedAccordions(props) {
  const { children, ...other } = props;

  return (
    <>
      <StyledAccordion {...other}>{children}</StyledAccordion>
    </>
  );
}

CustomizedAccordions.propTypes = {
  children: PropTypes.node.isRequired,
};

CustomizedAccordions.AccordionSummary = AccordionSummaryFun;
CustomizedAccordions.AccordionDetails = AccordionDetailsFun;

export default CustomizedAccordions;

import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Box } from '@mui/material';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import {
  getPPEInsightsConfig,
  updatePPEInsightsConfig,
} from '../../utils/apiHelper';
import { useToastContext } from '../../context/toastContext';
import Accordion from '../../components/Accordion';
import Switch from '../../components/Switch';
import LoadingIndicator from '../../components/LoadingIndicator';
import { BUTTON_TEXT, MESSAGE_STRINGS } from '../../constants/en-us';
import {
  ACCORDION_IDS,
  PPE_CONFIG_ACCORDIONS,
  QUERY_CONSTANTS,
  TEST_IDS,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import AccordionDownArrow from '../../assets/downArrow.svg';
import {
  BackdropContainer,
  ButtonTypography,
  ConfigContainer,
  ConfigTitle,
  CustomBackdrop,
  StyledButton,
  SubConfigContentTypography,
  SubConfigContentWrapper,
  SubConfigFooter,
  SubConfigTitle,
} from '../../utils/styles';

const initialSelectedAccordion = [
  { type: PPE_CONFIG_ACCORDIONS.UI_SETTINGS, expanded: true },
];

const PPEInsightsConfig = () => {
  const { rxjsState } = useRxjsState();
  const [selectedAccordion, setSelectedAccordion] = useState(
    initialSelectedAccordion
  );
  const [isPPEInsightsEnabled, setIsPPEInsightsEnabled] = useState(false);
  const [isUiSettingsBtnDisabled, setIsUiSettingsBtnDisabled] = useState(true);
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const onExpandUpdate = (expandedAccordion) => {
    setSelectedAccordion((prevAccordion) =>
      prevAccordion.map((item) => ({
        ...item,
        expanded: item.type === expandedAccordion ? !item.expanded : true,
      }))
    );
  };
  const accordionIsExpanded = (accordion) => {
    return selectedAccordion.find((item) => item.type === accordion).expanded;
  };

  const handleSwitchChange = (event) => {
    setIsSwitchChecked(event.target.checked);
  };

  useEffect(() => {
    if (isPPEInsightsEnabled !== isSwitchChecked) {
      setIsUiSettingsBtnDisabled(false);
    } else {
      setIsUiSettingsBtnDisabled(isPPEInsightsEnabled === isSwitchChecked);
    }
  }, [isSwitchChecked, isPPEInsightsEnabled]);

  const { toastDispatch } = useToastContext();

  const {
    refetch: refetchPPEInsightsConfig,
    isLoading: isPPEInsightsConfigLoading,
  } = useQuery({
    queryKey: [QUERY_CONSTANTS.GET_PPE_CONFIG],
    queryFn: () => getPPEInsightsConfig(rxjsState.plantId),
    onSuccess: (res) => {
      setIsSwitchChecked(res.ppe);
      setIsPPEInsightsEnabled(res.ppe);
    },
    onError: () => {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message: MESSAGE_STRINGS['Toast.message.ERROR'],
        },
      });
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(rxjsState?.plantId),
  });

  const {
    mutate: updatePPEInsightsConfigMutate,
    isLoading: isUpdatePPEInsightsConfigLoading,
  } = useMutation(
    [QUERY_CONSTANTS.POST_PPE_CONFIG],
    async (reqBody) => {
      return updatePPEInsightsConfig(reqBody);
    },
    {
      onSuccess: () => {
        refetchPPEInsightsConfig();
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: MESSAGE_STRINGS['Toast.message.SUCCESS'],
          },
        });
      },
      onError: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: MESSAGE_STRINGS['Toast.message.ERROR'],
          },
        });
      },
      enabled: rxjsState?.plantId,
    }
  );

  const handleUiSettingsConfigSave = () => {
    const reqBody = {
      status: isSwitchChecked,
      factoryId: rxjsState.plantId,
    };
    updatePPEInsightsConfigMutate(reqBody);
  };

  const showBackdropLoader =
    isUpdatePPEInsightsConfigLoading || isPPEInsightsConfigLoading;

  return (
    <Box>
      <ConfigTitle>{MESSAGE_STRINGS['Config.page.Title']}</ConfigTitle>
      <ConfigContainer>
        <Accordion
          expanded={accordionIsExpanded(PPE_CONFIG_ACCORDIONS.UI_SETTINGS)}
          onChange={() => {
            onExpandUpdate(PPE_CONFIG_ACCORDIONS.UI_SETTINGS);
          }}
        >
          <Accordion.AccordionSummary
            expandIcon={
              <AccordionDownArrow alt="down arrow" height={9} width={16} />
            }
            id={ACCORDION_IDS.UI_SETTINGS}
            data-testid={TEST_IDS.UI_SETTINGS_ACCORDION}
          >
            <SubConfigTitle>
              {MESSAGE_STRINGS['Config.header.uisettings']}
            </SubConfigTitle>
          </Accordion.AccordionSummary>
          <Accordion.AccordionDetails
            data-testid={TEST_IDS.UI_SETTINGS_CONTENT}
          >
            <Box>
              <SubConfigContentWrapper>
                <Box sx={{ marginLeft: '1rem' }}>
                  <SubConfigContentTypography>
                    {MESSAGE_STRINGS['UiSettings.Enable_ppe_insights']}
                  </SubConfigContentTypography>
                </Box>
                <Box sx={{ padding: '1rem' }}>
                  <Switch
                    onChange={handleSwitchChange}
                    checked={isSwitchChecked}
                    data-testid={TEST_IDS.PPE_TOGGLE}
                  />
                </Box>
              </SubConfigContentWrapper>
              <SubConfigFooter>
                <StyledButton
                  disabled={isUiSettingsBtnDisabled}
                  onClick={handleUiSettingsConfigSave}
                  data-testid={TEST_IDS.UI_SETTINGS_SAVE}
                >
                  <ButtonTypography>{BUTTON_TEXT.SAVE}</ButtonTypography>
                </StyledButton>
              </SubConfigFooter>
            </Box>
          </Accordion.AccordionDetails>
        </Accordion>
      </ConfigContainer>
      <BackdropContainer data-testid={TEST_IDS.BACKDROP}>
        <CustomBackdrop open={showBackdropLoader}>
          <LoadingIndicator />
        </CustomBackdrop>
      </BackdropContainer>
    </Box>
  );
};

export default PPEInsightsConfig;

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { globalstate$ } from '@smf/ui-util-app';
import PPEInsightsConfig from '../PPEInsightsConfig';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path={'config/ppe_insights'}
          element={
            globalstate$.value.isLoggedInUser ? (
              <PPEInsightsConfig />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          render={() => (
            <Route path="*" element={<Navigate to="/" replace />} />
          )}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

const PPE_CONFIG_ACCORDIONS = {
  UI_SETTINGS: 'ui-settings',
};

const QUERY_CONSTANTS = {
  GET_PPE_CONFIG: 'get-ppe-insights-config',
  POST_PPE_CONFIG: 'post-ppe-insights-config',
};

const ACCORDION_IDS = {
  UI_SETTINGS: 'ui-settings',
};

const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};

const TEST_IDS = {
  BACKDROP: 'backdrop',
  UI_SETTINGS_ACCORDION: 'ui-settings-accordion',
  UI_SETTINGS_CONTENT: 'ui-settings-content',
  UI_SETTINGS_SAVE: 'ui-settings-save-btn',
  PPE_TOGGLE: 'ppe-insights-switch',
};

export {
  PPE_CONFIG_ACCORDIONS,
  QUERY_CONSTANTS,
  ACCORDION_IDS,
  TOAST_TYPE,
  TOAST_REDUCER_CONSTANTS,
  TEST_IDS,
};

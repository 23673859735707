const MESSAGE_STRINGS = {
  'Config.page.Title': 'PPE Insights',
  'Config.header.uisettings': 'UI Settings',
  'UiSettings.Enable_ppe_insights': 'Enable PPE Insights',

  //Toast
  'Toast.message.SUCCESS': 'Changes have been saved successfully!',
  'Toast.message.ERROR': 'Something went wrong. Please try again later!',
  'Toast.message.WARNING': 'Oops, something went wrong!',
  'Toast.message.INFO': 'Please wait',
};

const BUTTON_TEXT = {
  SAVE: 'Save',
};

export { MESSAGE_STRINGS, BUTTON_TEXT };
